import React, { useState, useContext } from "react";
import { Button } from "../../components";
import { TransactionContext } from "../../store/context/TransactionContext";
import { useSnackbar } from "notistack";
import { IDepositData } from "../../store/types";
import styles from "./Deposit.module.scss";

const Deposit: React.FC<{ setDepositModal: React.Dispatch<React.SetStateAction<IDepositData | null>> }> = ({ setDepositModal }) => {
  const { loading } = useContext(TransactionContext);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);

  const packages = [10,50, 100, 300, 1000, 3000, 6000, 10000];

  const getDailyProfit = (amount: number): number => {
    if (amount >= 10 && amount <= 300) return amount * 0.01;
    if (amount >= 50 && amount <= 300) return amount * 0.01;
    if (amount >= 301 && amount <= 1000) return amount * 0.011;
    if (amount >= 1001 && amount <= 3000) return amount * 0.012;
    if (amount >= 3001 && amount <= 6000) return amount * 0.013;
    if (amount >= 6001 && amount <= 10000) return amount * 0.014;
    return 0;
  };

  const handleDeposit = () => {
    if (!selectedAmount) {
      enqueueSnackbar("Please select a package!", { variant: "warning" });
      return;
    }
    setDepositModal({
      modal: true,
      depositAmount: selectedAmount.toString(),
    });
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Select a Deposit Package</h3>

      <div className={styles.packageContainer}>
        {packages.map((amount) => (
          <Button
            key={amount}
            onClick={() => setSelectedAmount(amount)}
            className={selectedAmount === amount ? styles.selected : styles.packageButton}
          >
            {amount} USDT
          </Button>
        ))}
      </div>

      {selectedAmount !== null && (
        <p className={styles.profitText}>
          Daily Profit: {getDailyProfit(selectedAmount).toFixed(2)} USDT
        </p>
      )}

      <Button
        onClick={handleDeposit}
        disabled={loading || selectedAmount === null}
        className={styles.buyButton}
      >
        Deposit USDT
      </Button>
    </div>
  );
};

export default Deposit;
