import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Header, BottomMenu } from './components'; // Ensure BottomMenu is imported here
import { useEagerConnect } from './hooks/useEagerConnect';
import { Home } from './pages';
import ProfilePage from './pages/ProfilePage';
import LandingPage from './pages/LandingPage';
import TransactionHistory from './components/TransactionHistory'; // Adjust the path as necessary
import ChatWidget from './components/ChatWidget';
import PartnerDetails from '../src/utils/PartnerDetails';
// import Dashboard from "./nft/Dashboard"; // Adjust path accordingly

import { SnackbarProvider, useSnackbar } from 'notistack'; // Import SnackbarProvider and useSnackbar
import 'aos/dist/aos.css';
import AOS from 'aos';
import StakePage from './utils/Stake';
import StakingData from './utils/Stake';
import Staking from './utils/Stake';
import TokenTradingApp from '../src/utils/PartnerDetails';
// import HomePage from './nft/HomePage';

// Success and error notification functions using notistack
const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  // Success notification
  const notifySuccess = (message: string) => {
    enqueueSnackbar(message, { variant: 'success' });
  };

  // Error notification
  const notifyError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  return { notifySuccess, notifyError };
};

const App: React.FC = () => {
  useEffect(() => {
    AOS.init({
      easing: 'ease-out-cubic',
    });
  }, []);

  useEagerConnect();
  const location = useLocation();
  
  // Determine if BottomMenu should be shown
  const showBottomMenu = location.pathname === '/';
  
  // Get notification functions
  const { notifySuccess, notifyError } = useNotification();

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/landingpage" element={<LandingPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/transactions" element={<TransactionHistory />} />
          <Route path="/usdt" element={<Staking />} />
          <Route path="/taaaa" element={<TokenTradingApp />} />

        </Routes>
        {/* BottomMenu and other components */}
        <ChatWidget />
{/* <HomePage/> */}
      </div>
    </SnackbarProvider>
  );
};

export default App;
