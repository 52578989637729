import { useEffect, useState, useCallback } from "react";
import { ethers } from "ethers";
import stakingAbi from "../utils/abis/USDTStaking.json";
import "./Staking.scss";
import React from "react";

const STAKING_CONTRACT_ADDRESS = "0xd14cb1c6c89b07c6dcfa9561685b1ac2833ed778";
const USDT_CONTRACT_ADDRESS = "0x67d0C17B2c90ca0aa68e6d646eaD55db6a5B0A5A";

// پری ڈیفائنڈ سٹیکنگ پلانز
const stakingPlans = [
  { img: "https://images.lifestyleasia.com/wp-content/uploads/sites/6/2022/05/19195140/adidas-nft-bored-ape-810x524-1.jpg?tr=w-1200,h-900", amount: "100", duration: 7 },
  { img: "https://image-cdn.hypb.st/https%3A%2F%2Fhypebeast.com%2Fimage%2F2021%2F10%2Fbored-ape-yacht-club-nft-3-4-million-record-sothebys-metaverse-0.jpg?w=960&cbr=1&q=90&fit=max", amount: "500", duration: 30 },
  { img: "https://cdn.prod.www.spiegel.de/images/d2caafb1-70da-47e2-ba48-efd66565cde1_w1024_r0.9975262832405689_fpx44.98_fpy48.86.jpg", amount: "1000", duration: 90 },
  { img: "https://cdn.prod.website-files.com/6615636a03a6003b067c36dd/661ffd0dbe9673d914edca2d_6423fc9ca8b5e94da1681a70_Screenshot%25202023-03-29%2520at%252010.53.43.jpeg", amount: "2500", duration: 120 },
];


const Staking = () => {
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [signer, setSigner] = useState<ethers.Signer | null>(null);
  const [userAddress, setUserAddress] = useState<string | null>(null);
  const [referralAddress, setReferralAddress] = useState<string>("");
  const [isConnecting, setIsConnecting] = useState(false);
  const [userHasStaked, setUserHasStaked] = useState(false);
  const [userReferral, setUserReferral] = useState<string | null>(null);
  const [data, setData] = useState({
    totalStake: "0",
    totalEarnings: "0",
    level1Earnings: "0",
    level2Earnings: "0",
    level3Earnings: "0",
  });

  const connectWallet = useCallback(async () => {
    if (!window.ethereum) {
      alert("MetaMask is not installed. Please install it to use this feature.");
      return;
    }
    setIsConnecting(true);
    try {
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
      await web3Provider.send("eth_requestAccounts", []);
      const web3Signer = web3Provider.getSigner();
      const address = await web3Signer.getAddress();

      setProvider(web3Provider);
      setSigner(web3Signer);
      setUserAddress(address);
    } catch (error: any) {
      console.error("Wallet connection failed:", error);
      alert(error?.message || "Failed to connect wallet.");
    }
    setIsConnecting(false);
  }, []);

  const getStakingContract = useCallback(() => {
    if (!signer) return null;
    return new ethers.Contract(STAKING_CONTRACT_ADDRESS, stakingAbi, signer);
  }, [signer]);

  useEffect(() => {
    if (!signer || !userAddress) return;

    const fetchDashboardData = async () => {
      try {
        const contract = getStakingContract();
        if (!contract) return;

        const [totalStake, totalEarnings, level1Earnings, level2Earnings, level3Earnings] =
          await contract.getUserDashboardData(userAddress);

        setData({
          totalStake: ethers.utils.formatUnits(totalStake, 18),
          totalEarnings: ethers.utils.formatUnits(totalEarnings, 18),
          level1Earnings: ethers.utils.formatUnits(level1Earnings, 18),
          level2Earnings: ethers.utils.formatUnits(level2Earnings, 18),
          level3Earnings: ethers.utils.formatUnits(level3Earnings, 18),
        });
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, [userAddress, signer]);

  useEffect(() => {
    if (window.ethereum) {
      connectWallet();
      window.ethereum.on("accountsChanged", connectWallet);
      return () => window.ethereum.removeListener("accountsChanged", connectWallet);
    }
  }, [connectWallet]);

  const fetchUserStakingData = async () => {
    if (!signer || !userAddress) return;
    const stakingContract = getStakingContract();
    if (!stakingContract) return;

    try {
      const userInfo = await stakingContract.userInfo(userAddress);
      if (userInfo.totalStaked.gt(0)) {
        setUserHasStaked(true);
        setUserReferral(userInfo.referrer);
      }
    } catch (error) {
      console.error("Error fetching staking data:", error);
    }
  };

  useEffect(() => {
    if (userAddress) {
      fetchUserStakingData();
    }
  }, [userAddress]);

  const handleStake = async (amount: string, duration: number) => {
    if (!signer) return alert("Please connect your wallet first.");

    setLoading(true);
    try {
      const usdtContract = new ethers.Contract(USDT_CONTRACT_ADDRESS, [
        "function approve(address spender, uint256 amount) public returns (bool)",
        "function allowance(address owner, address spender) public view returns (uint256)"
      ], signer);

      const stakingContract = getStakingContract();
      if (!stakingContract) throw new Error("Staking contract not found");

      const parsedAmount = ethers.utils.parseUnits(amount, 18);
      const allowance = await usdtContract.allowance(userAddress, STAKING_CONTRACT_ADDRESS);

      if (allowance.lt(parsedAmount)) {
        console.log("Approving USDT...");
        const approveTx = await usdtContract.approve(STAKING_CONTRACT_ADDRESS, parsedAmount);
        await approveTx.wait();
      }

      console.log("Staking:", amount, "USDT for", duration, "days");

      const referrer = userHasStaked ? userReferral || ethers.constants.AddressZero : referralAddress;
      const tx = await stakingContract.stake(
        parsedAmount,
        duration * 24 * 60 * 60, // دن کو سیکنڈز میں کنورٹ کرنا
        referrer
      );

      await tx.wait();
      alert("Staking successful!");
      fetchUserStakingData();
    } catch (error: any) {
      console.error("Transaction failed:", error);
      alert(error?.message || "Staking failed.");
    }
    setLoading(false);
  };

  return (
    <div className="staking-container">
      <h2>Meta Fusion Staking</h2>

      {!userAddress ? (
        <button onClick={connectWallet} disabled={isConnecting}>
          {isConnecting ? "Connecting..." : "Connect Wallet"}
        </button>
      ) : (
        <p>Connected Wallet: {userAddress}</p>
      )}

      {userHasStaked && userReferral ? (
        <div className="referral-info">
          <p><strong>Your Referral:</strong> {userReferral}</p>
        </div>
      ) : (
        <div className="referral-input">
          <label>Enter Referral Address:</label>
          <input
            type="text"
            value={referralAddress}
            onChange={(e) => setReferralAddress(e.target.value)}
            placeholder="0x..."
          />
        </div>
      )}

      <div className="staking-plans">
        {stakingPlans.map((plan, index) => (
          <div className="staking-card" key={index}>
            
            <h3>{plan.amount} USDT</h3>
            <p>Duration: {plan.duration} Days</p>
            <button onClick={() => handleStake(plan.amount, plan.duration)} disabled={loading || !signer}>
              {loading ? "Staking..." : "Stake"}
            </button>
          </div>
        ))}
      </div>

      <div>
        <h2>Dashboard</h2>
        <p>Total Stake: {data.totalStake} USDT</p>
        <p>Total Earnings: {data.totalEarnings} USDT</p>
        <p>Level 1 Earnings: {data.level1Earnings} USDT</p>
        <p>Level 2 Earnings: {data.level2Earnings} USDT</p>
        <p>Level 3 Earnings: {data.level3Earnings} USDT</p>
      </div>
    </div>
  );
};

export default Staking;
