import React, { useEffect, useState } from "react";
import "./AlertMessage.scss"; // SCSS styles include modal styling

const AlertMessage: React.FC = () => {
  const targetDate = new Date("2025-02-17T00:00:00Z").getTime(); // Set your actual launch date here

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="alert-container">
      <div className="alert-box">
        <h2>🚀 TRUST TRADE New Update Alert! 🔥</h2>
        <p>
          This update is launching soon!  
          <h4>In just {timeLeft.days} days</h4>
          you will be able to claim your old investment's ROI daily! 💰
        </p>

        <div className="countdown">
          <span>{timeLeft.days}d</span> : 
          <span>{timeLeft.hours}h</span> : 
          <span>{timeLeft.minutes}m</span> : 
          <span>{timeLeft.seconds}s</span>
        </div>

        <button className="cta-button" onClick={() => setIsModalOpen(true)}>
          Reinvest Now & Earn More! 💰
        </button>
      </div>

      {/* Popup Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-box">
            <h2>🚀 TRT Token Secure Contract Update</h2>
            <p>
              Great news! The new 10-year contract has been signed with 
              Binance, KuCoin, and top exchanges, where TRT Token 
              will be listed soon! 🚀  
              This means:
            </p>
            <ul>
              <li>✅ Fully audited & secure contract</li>
              <li>✅ No risk of hacking or vulnerabilities</li>
              <li>✅ Direct integration with Binance for safe transactions</li>
              <li>✅ Long-term sustainability and transparency</li>
            </ul>
            <button className="close-button" onClick={() => setIsModalOpen(false)}>Close ❌</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertMessage;
