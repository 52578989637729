const TOKEN_ADDRESS = {
  56: "0x16963195bd97caba6ee12dc2350e662fac2e248a",
  4002 : "0x67d0C17B2c90ca0aa68e6d646eaD55db6a5B0A5A",
};

const STAKING_ADDRESS = {
  56: "0xfAeCA75F9704Cbc551e5b7363C1236B9b8F635D9",
  4002 : "0x6468d660e91168335e5604619412c51407b411bc",
};

export { TOKEN_ADDRESS, STAKING_ADDRESS };
