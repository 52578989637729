import React from 'react';
import NFTCard from './NFTCard';

const NFTs = [
  {
    title: 'COMMEN',
    imageUrl: 'https://images.lifestyleasia.com/wp-content/uploads/sites/6/2022/05/19195140/adidas-nft-bored-ape-810x524-1.jpg?tr=w-1200,h-900',
    description: 'This is the UNCOMMEN NFT on the card.',
    price: 100,
    tokenId: 1,
  },

  {
    title: 'UNCOMMEN',
    imageUrl: 'https://image-cdn.hypb.st/https%3A%2F%2Fhypebeast.com%2Fimage%2F2021%2F10%2Fbored-ape-yacht-club-nft-3-4-million-record-sothebys-metaverse-0.jpg?w=960&cbr=1&q=90&fit=max',
    description: 'This is the UNCOMMEN NFT on the card.',
    price: 600,
    tokenId: 2,
  },
  {
    title: 'RARE',
    imageUrl: 'https://cdn.prod.www.spiegel.de/images/d2caafb1-70da-47e2-ba48-efd66565cde1_w1024_r0.9975262832405689_fpx44.98_fpy48.86.jpg',
    description: 'This is the RARE NFT on the card.',
    price: 1200,
    tokenId: 3,
  },
  {
    title: 'LEGANDRY',
    imageUrl: 'https://cdn.prod.website-files.com/6615636a03a6003b067c36dd/661ffd0dbe9673d914edca2d_6423fc9ca8b5e94da1681a70_Screenshot%25202023-03-29%2520at%252010.53.43.jpeg',
    description: 'This is the LEGANDRY NFT on the card.',
    price: 300,
    tokenId: 4,
  },
  // Add more NFTs as needed
];

const NFTList: React.FC = () => {
  return (
    <div className="nft-list">
      {NFTs.map((nft) => (
        <NFTCard key={nft.tokenId} nft={nft} />
      ))}
    </div>
  );
};

export default NFTList;
