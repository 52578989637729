import { ethers, Event } from 'ethers';
import { loadStakingContract } from './contracts';

// Zero Address
export const zeroAddress = '0x0000000000000000000000000000000000000000';

// Sleep Function
export const sleep = (ms = 2000) => new Promise((resolve) => setTimeout(resolve, ms));

// ABI for the contract (inline ABI)
const stakingAbi = [
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'user', type: 'address' },
      { indexed: false, name: 'amount', type: 'uint256' }
    ],
    name: 'Staked',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'user', type: 'address' },
      { indexed: false, name: 'amount', type: 'uint256' }
    ],
    name: 'Reward',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'user', type: 'address' },
      { indexed: false, name: 'amount', type: 'uint256' },
      { indexed: false, name: 'stakeIndex', type: 'uint256' }
    ],
    name: 'Claimed',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'user', type: 'address' },
      { indexed: false, name: 'amount', type: 'uint256' },
      { indexed: false, name: 'stakeIndex', type: 'uint256' }
    ],
    name: 'Withdrawn',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'user', type: 'address' },
      { indexed: false, name: 'amount', type: 'uint256' }
    ],
    name: 'Withdrawal',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'user', type: 'address' },
      { indexed: false, name: 'amount', type: 'uint256' }
    ],
    name: 'ReferralEarningsWithdrawn',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: 'bronze', type: 'uint256' },
      { indexed: false, name: 'silver', type: 'uint256' },
      { indexed: false, name: 'gold', type: 'uint256' }
    ],
    name: 'ReferralRewardsUpdated',
    type: 'event'
  },
  {
    constant: true,
    inputs: [{ name: 'user', type: 'address' }],
    name: 'getUserStatus',
    outputs: [{ name: 'status', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
];

export const getTotalWithdrawals = async (
  contractAddress: string, // Contract address
  provider: ethers.providers.Provider, // Ethereum provider
  chainId: number, // Network chain ID
  userAddress: string // Address of the user
): Promise<string> => {
  // Load the staking contract using the loadStakingContract utility
  const stakingContract = loadStakingContract(contractAddress, provider, chainId);

  try {
    // Call the `totalWithdrawals` function on the smart contract
    const totalWithdrawals = await stakingContract.totalWithdrawals(userAddress);
    
    // Format the withdrawals to display them in human-readable format (assuming 18 decimals)
    return ethers.utils.formatUnits(totalWithdrawals, 18); // Adjust the decimals if necessary
  } catch (error) {
    console.error('Error fetching total withdrawals:', error);
    return '0'; // Return a default value in case of an error
  }
};

export const getEarningsAll = async (
  contractAddress: string, // Contract address
  provider: ethers.providers.Provider, // Ethereum provider
  chainId: number, // Network chain ID
  userAddress: string // Address of the user
): Promise<string> => {
  // Load the staking contract using the loadStakingContract utility
  const stakingContract = loadStakingContract(contractAddress, provider, chainId);

  try {
    // Call the `getEarningsAll` function on the smart contract
    const totalEarnings = await stakingContract.getEarningsAll(userAddress);
    
    // Format the earnings to display them in human-readable format (assuming 18 decimals)
    return ethers.utils.formatUnits(totalEarnings, 18); // Adjust the decimals if necessary
  } catch (error) {
    console.error('Error fetching total earnings:', error);
    return '0'; // Return a default value in case of an error
  }
};

export enum UserLevelStatus {
  NEWBIE = 'NEWBIE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  BRONZI = 'BRONZI',
  DIAMOND = 'DIAMOND'
}

export const checkUserLevel = async (
  address: string,
  provider: ethers.providers.Provider,
  chainId: number,
  userAddress: string
): Promise<string> => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  try {
    const level = await stakingContract.getUserLevel(userAddress);
    console.log('User Level:', level);

    // Mapping user level to labels
    switch (level) {
      case 0:
        return 'Newbie';
      case 1:
        return 'Silver';
      case 2:
        return 'Gold';
      case 3:
        return 'Diamond';
      default:
        return 'Unknown'; // Return 'Unknown' if level is outside expected range
    }
  } catch (error) {
    console.error('Error fetching user level:', error);
    return 'Error'; // Return a default value or handle the error accordingly
  }
};


export const checkStatus = async (
  address: string,
  provider: ethers.providers.Provider,
  chainId: number,
  userAddress: string
): Promise<string> => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  try {
    const status = await stakingContract.getUserStatus(userAddress);
    console.log('User Status:', status);
    return status; // Return status for further use
  } catch (error) {
    console.error('Error fetching user status:', error);
    return 'Error'; // Return a default value or handle the error accordingly
  }
};

export const claimAll = async (
  address: string, // contract address
  provider: ethers.providers.Provider, // Ethereum provider
  chainId: number // Network chain ID
): Promise<void> => {
  // Load the staking contract using the loadStakingContract utility
  const stakingContract = loadStakingContract(address, provider, chainId);

  try {
    // Call the claimAll function on the smart contract
    const tx = await stakingContract.claimAll();
    
    // Wait for the transaction to be mined
    await tx.wait();

    console.log('Successfully claimed all available earnings.');
  } catch (error) {
    console.error('Error claiming all earnings:', error);
  }
};


export const getEarningsLast24Hours = async (
  address: string,
  provider: ethers.providers.Provider,
  chainId: number,
  userAddress: string
): Promise<string> => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  try {
    const earnings = await stakingContract.getEarningsLast24Hours(userAddress);
    return ethers.utils.formatUnits(earnings, 18); // Adjust the decimals if necessary
  } catch (error) {
    console.error('Error fetching earnings for the last 24 hours:', error);
    return "0"; // Return a default value in case of an error
  }
};




export const getTotalClaimedEarnings = async (
  userAddress: string,
  provider: ethers.providers.Provider,
  chainId: number
): Promise<string> => {
  // Replace with your actual contract address
  const stakingContractAddress = "YOUR_CONTRACT_ADDRESS";
  const stakingContract = new ethers.Contract(stakingContractAddress, stakingAbi, provider);
  
  try {
    // Replace with your actual method name and parameters
    const claimedEarnings = await stakingContract.getTotalClaimedEarnings(userAddress);
    return ethers.utils.formatUnits(claimedEarnings, 18); // Adjust the decimals if necessary
  } catch (error) {
    console.error("Error fetching claimed earnings:", error);
    return "0"; // Return a default value in case of an error
  }
};

// Functions



// Set Stake
export const setStake = async (
  address: string,
  provider: ethers.providers.Provider,
  chainId: number,
  amount: string,
  referrer: string | null
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);
  const parseAmount = ethers.utils.parseEther(amount);

  const ref = referrer || ethers.constants.AddressZero;
  console.log('Referrer Address:', ref);

  try {
    const tx = await stakingContract.stake(parseAmount, ref);
    await tx.wait();
  } catch (error) {
    console.error('Error setting stake:', error);
  }
};

// Claim and Withdraw
export const claimAndWithdraw = async (
  address: string,
  provider: ethers.providers.Provider,
  chainId: number,
  stakeIndex: number
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  try {
    const tx = await stakingContract.withdraw(stakeIndex);
    await tx.wait();
  } catch (error) {
    console.error('Error claiming and withdrawing:', error);
  }
};

// Claim
export const claim = async (
  address: string,
  provider: ethers.providers.Provider,
  chainId: number,
  stakeIndex: number
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  try {
    const tx = await stakingContract.claim(stakeIndex);
    await tx.wait();
  } catch (error) {
    console.error('Error claiming:', error);
  }
};

// Withdraw Referral Earnings
export const withdrawReferralEarnings = async (
  address: string,
  provider: ethers.providers.Provider,
  chainId: number
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  try {
    const tx = await stakingContract.withdrawReferralEarnings();
    await tx.wait();
  } catch (error) {
    console.error('Error withdrawing referral earnings:', error);
  }
};

// Get Contract Details
export const getContractDetails = async (
  address: string,
  provider: ethers.providers.Provider,
  chainId: number
) => {
  const stakingContract = loadStakingContract(address, provider, chainId);

  try {
    const totalStaked = await stakingContract.getTotalStaked();
    return {
      totalStaked: ethers.utils.formatEther(totalStaked.toString()),
    };
  } catch (error) {
    console.error('Error fetching contract details:', error);
    return {
      totalStaked: '0',
    };
  }
};

// Fetch Recent Activity including Staked Events


export const getUserActivity = async (
  contractAddress: string,
  provider: ethers.providers.JsonRpcProvider,
  userAddress: string
) => {
  if (!ethers.utils.isAddress(userAddress)) {
    console.error('Invalid user address:', userAddress);
    return [];
  }

  const contract = new ethers.Contract(contractAddress, stakingAbi, provider);

  try {
    const currentBlock = await provider.getBlockNumber();
    console.log('Current Block:', currentBlock);

    // Fetching Events Filtered by User Address
    const eventsToFetch = [
      { filter: contract.filters.Staked(userAddress), eventName: 'Staked' },
      { filter: contract.filters.Reward(userAddress), eventName: 'Reward' },
      { filter: contract.filters.Claimed(userAddress), eventName: 'Claimed' },
      { filter: contract.filters.Withdrawn(userAddress), eventName: 'Withdrawn' },
      { filter: contract.filters.ReferralEarningsWithdrawn(userAddress), eventName: 'ReferralEarningsWithdrawn' }
    ];

    // Fetch all events for the given user address
    const allEvents = await Promise.all(
      eventsToFetch.map(async ({ filter, eventName }) => {
        const events = await contract.queryFilter(filter, currentBlock - 10000, currentBlock);
        return Promise.all(
          events.map(async (event) => {
            const block = await provider.getBlock(event.blockNumber);
            return {
              name: eventName,
              args: event.args ?? {},
              blockNumber: event.blockNumber,
              timestamp: new Date(block.timestamp * 1000).toLocaleString(),
            };
          })
        );
      })
    );

    const flatEvents = allEvents.flat();

    return flatEvents.sort((a, b) => b.blockNumber - a.blockNumber);
  } catch (error) {
    console.error('Error fetching user activity:', error);
    return [];
  }
};

// Fetch Recent Activity Including All Relevant Events
export const fetchRecentActivity = async (
  contractAddress: string,
  provider: ethers.providers.JsonRpcProvider
) => {
  const contract = new ethers.Contract(contractAddress, stakingAbi, provider);
  const currentBlock = await provider.getBlockNumber();

  const filters = [
    { filter: contract.filters.Staked(), eventName: 'Staked' },
    { filter: contract.filters.Reward(), eventName: 'Reward' },
    { filter: contract.filters.Claimed(), eventName: 'Claimed' },
    { filter: contract.filters.Withdrawn(), eventName: 'Withdrawn' },
    { filter: contract.filters.ReferralEarningsWithdrawn(), eventName: 'ReferralEarningsWithdrawn' },
  ];

  try {
    const allEvents = await Promise.all(
      filters.map(async ({ filter, eventName }) => {
        const events = await contract.queryFilter(filter, currentBlock - 10000, currentBlock);
        return events.map((event) => ({
          name: eventName,
          user: event.args?.user ?? '',
          // Format the amount to display it correctly without extra zeros
          amount: event.args?.amount ? parseFloat(ethers.utils.formatUnits(event.args.amount, 18)).toFixed(2) : '0.00',
          stakeIndex: event.args?.stakeIndex?.toString() ?? '',
          blockNumber: event.blockNumber,
          transactionHash: event.transactionHash,
        }));
      })
    );

    return allEvents.flat().sort((a, b) => b.blockNumber - a.blockNumber);
  } catch (error) {
    console.error('Error fetching recent activity:', error);
    return [];
  }
};

export { loadStakingContract };
// Get User Activity
