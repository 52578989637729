import React from 'react';
import './NFTCard.scss';

interface INFT {
  title: string;
  imageUrl: string;
  description: string;
  price: number;
  tokenId: number;
}

const NFTCard: React.FC<{ nft: INFT }> = ({ nft }) => {
  return (
    <div className="nft-card">
      <img className="nft-card__image" src={nft.imageUrl} alt={nft.title} />
      <div className="nft-card__content">
        <h3 className="nft-card__title">{nft.title}</h3>
        <p className="nft-card__description">{nft.description}</p>
        <div className="nft-card__footer">
          <span className="nft-card__price">${nft.price}</span>
          <button className="nft-card__buy-button">RESAVATION</button>
        </div>
      </div>
    </div>
  );
};

export default NFTCard;
